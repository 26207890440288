import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/industry",
    name: "industry",
    component: () => import("../views/industry/Index.vue"),
    meta: { title: "核心服务" },
    children: [
      {
        path: "gonglu",
        component: () => import("../views/industry/Gonglu.vue"),
        meta: { title: "公路运输" },
      },
      {
        path: "tielu",
        component: () => import("../views/industry/Tielu.vue"),
        meta: { title: "铁路运输" },
      },
      {
        path: "shuilu",
        component: () => import("../views/industry/Shuilu.vue"),
        meta: { title: "水路运输" },
      },
      {
        path: "cangchu",
        component: () => import("../views/industry/Cangchu.vue"),
        meta: { title: "仓储统配" },
      },
    ],
    redirect: "/industry/gonglu",
  },
  {
    path: "/newsCenter",
    name: "newsCenter",
    component: () => import("../views/newsCenter/Index.vue"),
    meta: { title: "新闻中心" },
    children: [
      {
        path: "list",
        component: () => import("../views/newsCenter/NewsList.vue"),
      },
      {
        path: "news",
        component: () => import("../views/newsCenter/News.vue"),
        meta: { title: "详情" },
      },
    ],
    redirect: "/newsCenter/list",
  },
  {
    path: "/join",
    name: "join",
    component: () => import("../views/Join.vue"),
    meta: { title: "加入我们" },
  },
  // {
  //   path: "/contact",
  //   name: "contact",
  //   component: () => import("../views/contact/Index.vue"),
  //   meta: { title: "联系我们" },
  // },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about/Index.vue"),
    meta: { title: "关于我们" },
  },
];

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      selector: '#container',
    };
  },
});

export default router;
