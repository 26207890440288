import request from '@/utils/request'

// 查询文章列表
export function listArticle(query) {
  return request({
    url: '/system/article/infoList',
    method: 'get',
    params: query
  })
}

// 查询文章详细
export function getArticle(id) {
  return request({
    url: '/system/article/' + id,
    method: 'get'
  })
}

// 浏览量+1
export function addView(id) {
  return request({
    url: '/system/article/addView',
    method: 'get',
    params:{id}
  })
}

// 浏览量+1
export function addLike(id) {
  return request({
    url: '/system/article/addLike',
    method: 'get',
    params:{id}
  })
}
