import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import { parseTime, resetForm,imgURL,firstImgUrl,imgUrlArr } from "@/utils/teng";
import Config from '@/components/Config';

Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.imgURL = imgURL
Vue.prototype.firstImgUrl = firstImgUrl
Vue.prototype.imgUrlArr = imgUrlArr
Vue.config.productionTip = false
Config.install()    //请求配置文件
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')