<template>
  <div class="nav">
    <div class="nav-c">
      <div class="nav-c1">
        <div class="c1-h">
          <li class="head">企业概况</li>
          <li><router-link to="/about?tab=culture">企业文化</router-link></li>
          <li><router-link to="/about?tab=course">发展历程</router-link></li>
          <li><router-link to="/about?tab=matrix">办公环境</router-link></li>
          <li><router-link to="/about?tab=honer">企业荣誉</router-link></li>
        </div>
        <div class="c1-h">
          <li class="head">解决方案</li>
          <li><router-link to="/industry/gonglu">公路运输</router-link></li>
          <li><router-link to="/industry/tielu">铁路运输</router-link></li>
          <li><router-link to="/industry/shuilu">水路运输</router-link></li>
          <li><router-link to="/industry/cangchu">仓储统配</router-link></li>
        </div>
        <div class="c1-h">
          <li class="head">新闻中心</li>
          <li><router-link to="/newsCenter?type=0">公司新闻</router-link></li>
          <li><router-link to="/newsCenter?type=1">行业资讯</router-link></li>
        </div>
        <div class="c1-h">
          <li class="head">人力资源</li>
          <li><router-link to="/join">招聘信息</router-link></li>
        </div>
      </div>
      <div class="nav-c2">
        <img src="../assets/logo-b.png" class="c2-img" />
        <div class="c2-div">
          <span>全国服务热线：
            <a
              :href="'tel:' + $store.state.config['contact_info'].label1">{{ $store.state.config['contact_info'].label1 }}</a>
          </span>
          <span style="margin-left: 4em;">邮箱：<a
              :href="'mailto:email@' + $store.state.config['contact_info'].label2 + '?subject=邮件标题&body=邮件内容'">{{ $store.state.config['contact_info'].label2 }}</a></span>
        </div>
        <div class="c2-txt">总部地址：{{ $store.state.config['contact_info'].label3 }}</div>
      </div>
      <div class="nav-c3">
        <el-image style="width: 100px" :src="$store.state.config['contact_info'].img" class="c3-img"
          :preview-src-list="[$store.state.config['contact_info'].img]">
        </el-image>
        <div class="c3-txt1">{{ $store.state.config['contact_info'].label4 }}</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="nav-d">
      <span style="color: #dddddd;">Copyright © </span>
      <span style="color: #dddddd;margin-left: 10px;">湖南安迅物流运输有限公司 </span>
      <span style="color: #dddddd;margin-left: 20px;">
        <img src="../assets/img/gongan.png" style="width: 14px;" />
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo"
          style="text-decoration:none;height:20px;line-height:20px;" target="_blank">湘公网安备
          43020039011-20001号</a>
      </span>
      <span style="color: #dddddd;margin-left: 20px;"><a href="http://beian.miit.gov.cn/"
          target="_blank">湘ICP备20005592号</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomNav',
  props: {
    msg: String
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.nav {
  width: 100%;
  background: #17213d;
  align-items: center;
  padding-top: 20px;
}

.nav-c {
  width: 80%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
}

.nav-c1 {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.c1-h li {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  list-style-type: none;
  margin-bottom: 10px;
}

.c1-h .head {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #dddddd;
  list-style-type: none;
  margin-bottom: 36px;
}

.nav-c2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 100px;
}

.c2-img {
  width: 180px;
}

.c2-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 50px;
}

.c2-txt {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 20px;
}


.nav-c3 {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c3-img {
  width: 120px;
}

.c3-txt1 {
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 10px;
}

.line {
  width: 80%;
  height: 1px;
  background: #444444;
  margin: auto;
}

.nav-d {
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}

a {
  text-decoration: none;
  color: #999999;
}

.router-link-active {
  text-decoration: none;
  color: #FFFFFF;
}</style>
