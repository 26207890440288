<template>
  <div id="app">
    <el-backtop></el-backtop>
    <HeadNav style="position: absolute;top: 0;"></HeadNav>
    <router-view/>
    <BottomNav></BottomNav>
  </div>
</template>

<script>
import BottomNav from './views/BottomNav.vue';
import HeadNav from './views/HeadNav.vue';
export default {
  name: 'App',
  components: {
    BottomNav,HeadNav
  },
  data() {
    return {

    }
  },
}
</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
</style>
