import Vue from "vue";
import Vuex from "vuex";
import { getAllGwConfig } from "@/api/gwConfig";
import { getAllHomeConfig } from "@/api/homeConfig";
import defaultConfig from "@/components/Config/default"; //默认配置

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: defaultConfig,
  },
  getters: {},
  mutations: {
    SET_CONFIG: (state, config) => {
      Object.assign(state.config, config);
    },
  },
  actions: {
    initConfig({ commit, state }) {
      getAllGwConfig()
        .then((res) => {
          if (res.data) {
            let conf = res.data.reduce((conf, item) => {
              conf[item.type] = item;
              return conf;
            }, {});
            commit("SET_CONFIG", conf);
          }
        })
        .catch((err) => {
          commit("SET_CONFIG", defaultConfig);
        });
    },
  },
  modules: {},
});
