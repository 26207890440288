export default {
  contact_info: {
    id: 1,
    parentId: 0,
    type: "contact_info",
    name: null,
    label1: "(0731)8256 6801",
    label2: "email@anxun56.cn",
    label3: "湖南省长沙市开福区青竹湖街道湘江北路一段369号5A栋601-606",
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1692950692512.jpg",
    content: null,
    link: null,
    children: [],
  },
  home_banner: {
    id: 2,
    parentId: 0,
    type: "home_banner",
    name: null,
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1700441149945.jpg,https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1700441149959.jpg",
    content: null,
    link: null,
    children: [],
  },
  home_industry: {
    id: 5,
    parentId: 0,
    type: "home_industry",
    name: "业务板块",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 6,
        parentId: 5,
        type: "home_industry",
        name: "公路运输",
        label1: "网络货运 | 专线 | 零担",
        label2: "",
        label3: "",
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693271572380.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 7,
        parentId: 5,
        type: "home_industry",
        name: "铁路运输",
        label1: "中欧班列 | 湘粤通 | 货运代理",
        label2: "",
        label3: "",
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693271614107.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 8,
        parentId: 5,
        type: "home_industry",
        name: "水路运输",
        label1: "城陵矶港 | 霞凝港 | 石龙港",
        label2: "",
        label3: "",
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693271655139.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 9,
        parentId: 5,
        type: "home_industry",
        name: "仓储统配",
        label1: "汽车贸易 | 仓储配送",
        label2: "",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693271690580.png",
        content: null,
        link: null,
        children: [],
      },
    ],
  },
  home_about: {
    id: 10,
    parentId: 0,
    type: "home_about",
    name: "公司简介",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1700441375943.png",
    content:
      "<p><span style=\"color: rgb(255, 255, 255);\">﻿湖南安迅物流运输有限公司于2006年10月注册成立，注册资本1539万元。专业货物运输、集装箱运输、多式联运业务、仓储服务、外贸、报关、报检、国际业务代理咨询服务。2019年6月取得湖南省无车承运人企业资质，2021年获得国家5A综合服务型物流企业证书。现有员工210人，资产总额3.2亿元，年货运量近千万吨，年发运集装箱10万多标箱。旗下子公司有：湖南霞凝内陆港有限公司、湖南安迅物流科技有限公司、株洲安迅供应链管理有限公司、衡阳安迅物流有限公司、湖南硕林供应链管理有限公司等。安迅物流以经济、快捷、安全、周到为服务宗旨，按照现代物流企业的标准，采用先进的物流运作方式和运输装备，建立了标准化的运输管理信息系统、ISO9001质量管理体系以及车辆GPS管理系统。</span></p>",
    link: null,
    children: [],
  },
  home_cooperation_ziyuan: {
    id: 11,
    parentId: 0,
    type: "home_cooperation_ziyuan",
    name: "合作资源",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 12,
        parentId: 11,
        type: "home_cooperation_ziyuan",
        name: "招商局集团",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272023281.jpg",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 13,
        parentId: 11,
        type: "home_cooperation_ziyuan",
        name: "中集集团",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272056027.jpg",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 14,
        parentId: 11,
        type: "home_cooperation_ziyuan",
        name: "美集",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272135103.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 15,
        parentId: 11,
        type: "home_cooperation_ziyuan",
        name: "中远",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272154681.jpg",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 16,
        parentId: 11,
        type: "home_cooperation_ziyuan",
        name: "广铁",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272172524.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 17,
        parentId: 11,
        type: "home_cooperation_ziyuan",
        name: "盐田港",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272290385.jpg",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 18,
        parentId: 11,
        type: "home_cooperation_ziyuan",
        name: "盐田集装箱",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272352070.jpg",
        content: null,
        link: null,
        children: [],
      },
    ],
  },
  home_cooperation_kehu: {
    id: 19,
    parentId: 0,
    type: "home_cooperation_kehu",
    name: "客户资源",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 20,
        parentId: 19,
        type: "home_cooperation_kehu",
        name: "北汽",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272529105.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 21,
        parentId: 19,
        type: "home_cooperation_kehu",
        name: "比亚迪",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272544556.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 22,
        parentId: 19,
        type: "home_cooperation_kehu",
        name: "富士康",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272563102.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 23,
        parentId: 19,
        type: "home_cooperation_kehu",
        name: "福田",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272581779.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 24,
        parentId: 19,
        type: "home_cooperation_kehu",
        name: "华联",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272600639.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 25,
        parentId: 19,
        type: "home_cooperation_kehu",
        name: "惠普",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272617065.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 26,
        parentId: 19,
        type: "home_cooperation_kehu",
        name: "蓝思",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272633444.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 27,
        parentId: 19,
        type: "home_cooperation_kehu",
        name: "旗滨",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272650242.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 28,
        parentId: 19,
        type: "home_cooperation_kehu",
        name: "三一",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272668017.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 29,
        parentId: 19,
        type: "home_cooperation_kehu",
        name: "晟通",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693272686665.png",
        content: null,
        link: null,
        children: [],
      },
    ],
  },
  about_info: {
    id: 30,
    parentId: 0,
    type: "about_info",
    name: "关于我们",
    label1: "成为一家创新、智能、绿色供应链企业",
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693300453514.png",
    content:"<p>湖南安迅物流运输有限公司注册成立于2006年10月，注册资本1539万元。专业从事音通货物运输、货物专用运输（集装箱）、多式联运业务、仓储服务、外贸咨询服务等业务。2015年获批国家4A综合服务型物流企业，2019年取得湖南省无车承运人企业资质。现拥有员工210人，资产总额1.2亿元。年货运量400万吨，年发运集装箱3万多标箱。</p><p>公司总部位于湖南醴陵经开区陶瓷特色小镇，在长沙、岳阳、湘潭、株洲、衡阳、江西南昌、浙江义乌、广东韶关和深圳等地区设立有分部及营业网点20余个。拥有两个物流园，立三物流园和经开区物流园，占地面积200亩。六个铁路作业货场：醴陵南站货场、株洲北、湘潭易家湾货场、长沙北霞凝货场、衡阳货场和韶关货场。</p><p>在5G时代的引领下，安迅物流立足陶瓷特色小镇，肩负陶瓷产业供给侧优化改革发展使命，充分利用互联网＋物流的理念，不断创新物流技术，自主开发网络货运公共服务平台，创新供应链管理，物流信息资源高度共享，提高物流效率和服务质量，为上下游客商降低物流成本。2020年安迅科技网络货运平台正式上线，助推互联网＋物流服务业务拓展。</p>",
    link: null,
    children: [],
  },
  about_card: {
    id: 31,
    parentId: 0,
    type: "about_card",
    name: "关键词",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 32,
        parentId: 31,
        type: "about_card",
        name: "2006",
        label1: "公司建立于2006年",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 33,
        parentId: 31,
        type: "about_card",
        name: "210+",
        label1: "在职员工210+",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 34,
        parentId: 31,
        type: "about_card",
        name: "1000万+",
        label1: "年货运量1000万+",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 35,
        parentId: 31,
        type: "about_card",
        name: "10000+",
        label1: "年品质服务量10000+",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
    ],
  },
  about_culture_card: {
    id: 36,
    parentId: 0,
    type: "about_culture_card",
    name: "文化卡片",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 37,
        parentId: 36,
        type: "about_culture_card",
        name: "企业使命",
        label1:
          "追求全体家人物心双幸福的同时，为客户提供安心、迅捷的供应链服务、为社会做出贡献",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 38,
        parentId: 36,
        type: "about_culture_card",
        name: "愿景",
        label1: "成为一家创新、智能、绿色供应链企业",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 39,
        parentId: 36,
        type: "about_culture_card",
        name: "价值观",
        label1: "以心为本",
        label2: "内求利他",
        label3: "诚信担当",
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
    ],
  },
  about_culture_album: {
    id: 40,
    parentId: 0,
    type: "about_culture_album",
    name: "文化墙",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 41,
        parentId: 40,
        type: "about_culture_album",
        name: "党建活动",
        label1: "Party Building Activities",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693300793595.jpg,https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693300793595.jpg",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 42,
        parentId: 40,
        type: "about_culture_album",
        name: "活动留影",
        label1: "Photo Of Event",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 43,
        parentId: 40,
        type: "about_culture_album",
        name: "培训提升",
        label1: "Training Enhancement",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 44,
        parentId: 40,
        type: "about_culture_album",
        name: "素质拓展",
        label1: "Quality Outreach",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 45,
        parentId: 40,
        type: "about_culture_album",
        name: "员工关怀",
        label1: "Employee Care",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
    ],
  },
  about_course: {
    id: 46,
    parentId: 0,
    type: "about_course",
    name: "发展历程",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 47,
        parentId: 46,
        type: "about_course",
        name: "安迅成立",
        label1: "2006",
        label2: "成立湖南安迅物流运输有限公司",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693300918602.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 48,
        parentId: 46,
        type: "about_course",
        name: "海铁多式联运",
        label1: "2007",
        label2: "启动海铁多式联运开通醴陵——深圳五定班列",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693300947815.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 49,
        parentId: 46,
        type: "about_course",
        name: "招商合资",
        label1: "2012",
        label2: "与招商合资成立湖南霞凝内陆港有限公司",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693300981286.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 50,
        parentId: 46,
        type: "about_course",
        name: "集货中心",
        label1: "2013",
        label2: "安迅立三公路运输集货中心建成",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693301011100.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 51,
        parentId: 46,
        type: "about_course",
        name: "合资扩股",
        label1: "2015",
        label2: "华联集团参与合资扩股",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693301320111.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 52,
        parentId: 46,
        type: "about_course",
        name: "甩挂运输试点",
        label1: "2017",
        label2: "安迅甩挂运输场站建成甩挂项目试点",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693301347854.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 53,
        parentId: 46,
        type: "about_course",
        name: "醴陵港、易家湾货场",
        label1: "2018",
        label2: "开通盐田国际内陆港醴陵港，湘潭易家湾货场建成并投入使用",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693301373302.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 54,
        parentId: 46,
        type: "about_course",
        name: "网络货运",
        label1: "2019",
        label2: "网络货运平台上线",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693301398835.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 55,
        parentId: 46,
        type: "about_course",
        name: "入驻1915",
        label1: "2020",
        label2: "入驻1915陶瓷特色小镇",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693301424848.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 56,
        parentId: 46,
        type: "about_course",
        name: "平盐仓库",
        label1: "2021",
        label2: "投建深圳平盐仓库",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693301450222.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 57,
        parentId: 46,
        type: "about_course",
        name: "路口铺货场",
        label1: "2022",
        label2: "岳阳路口铺多式联运货场营运",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693301474974.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 58,
        parentId: 46,
        type: "about_course",
        name: "郴州湘粤非",
        label1: "2023",
        label2: "携手郴州政府共建郴州湘粤非通道",
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693301497814.png",
        content: null,
        link: null,
        children: [],
      },
    ],
  },
  about_matrix_album: {
    id: 59,
    parentId: 0,
    type: "about_matrix_album",
    name: "办公环境",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 60,
        parentId: 59,
        type: "about_matrix_album",
        name: "长沙总部",
        label1: "Chang Sha",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1700442667263.jpg,https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1700442667355.jpg,https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1700442667447.jpg,https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1700442667457.jpg,https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1700442667470.jpg,https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1700442667536.jpg",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 61,
        parentId: 59,
        type: "about_matrix_album",
        name: "醴陵办公室",
        label1: "Li Ling",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 62,
        parentId: 59,
        type: "about_matrix_album",
        name: "岳阳办公室",
        label1: "Yue Yang",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 63,
        parentId: 59,
        type: "about_matrix_album",
        name: "郴州办公室",
        label1: "Chen Zhou",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
    ],
  },
  about_honer_album: {
    id: 64,
    parentId: 0,
    type: "about_honer_album",
    name: "荣誉相册墙",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 65,
        parentId: 64,
        type: "about_honer_album",
        name: "客户荣誉",
        label1: "Customer Honors",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 66,
        parentId: 64,
        type: "about_honer_album",
        name: "社会荣誉",
        label1: "Social Honors",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 67,
        parentId: 64,
        type: "about_honer_album",
        name: "创新及资质认证",
        label1: "Innovation & Qualification",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693354826857.png",
        content: null,
        link: null,
        children: [],
      },
    ],
  },
  industry_gonglu: {
    id: 68,
    parentId: 0,
    type: "industry_gonglu",
    name: "公路运输",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 69,
        parentId: 68,
        type: "industry_gonglu",
        name: "整车运输",
        label1: "",
        label2: "",
        label3: "",
        label4: null,
        label5: null,
        label6: null,
        labelArr:
          "全国各地内贸货物发送,聚焦湖南和广东外贸出口,湖南--广深，株洲--义乌，长株潭--云贵川等多条公路直达专线",
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693355129300.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 70,
        parentId: 68,
        type: "industry_gonglu",
        name: "零担运输",
        label1: "",
        label2: "",
        label3: "",
        label4: null,
        label5: null,
        label6: null,
        labelArr: "高效快捷,品质高端,价格优惠",
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693355170007.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 71,
        parentId: 68,
        type: "industry_gonglu",
        name: "网络货运",
        label1: "",
        label2: "",
        label3: "",
        label4: "",
        label5: "",
        label6: null,
        labelArr:
          "托运管理 —— 管理运单信息,资金管理 —— 充值提现管理,账户管理 —— 企业信息认证管理,结算管理 —— 运单三方结算管理,报表管理 —— 开票报表管理",
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693355228055.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 72,
        parentId: 68,
        type: "industry_gonglu",
        name: "货运资源",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [
          {
            id: 73,
            parentId: 72,
            type: "industry_gonglu",
            name: "站点专线",
            label1: "",
            label2: "",
            label3: "",
            label4: "",
            label5: null,
            label6: null,
            labelArr:
              "站点,长沙、醴陵、株洲、湘潭、益阳、宁乡、岳阳、衡阳、韶关、萍乡、义乌、深圳、广州,专线,湖南--广深，株洲--义乌，长株潭--云贵川",
            img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693355463638.png",
            content: null,
            link: null,
            children: [],
          },
          {
            id: 74,
            parentId: 72,
            type: "industry_gonglu",
            name: "车辆设备",
            label1: "",
            label2: "",
            label3: "",
            label4: "",
            label5: null,
            label6: null,
            labelArr:
              "自有运输车辆一百六十辆,自卸板和挂板两百余块,重型装卸设备正面吊、叉车、铲车、抓机若干,干线运输合作车辆10000台以上",
            img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693355594853.png",
            content: null,
            link: null,
            children: [],
          },
        ],
      },
    ],
  },
  industry_tielu: {
    id: 75,
    parentId: 0,
    type: "industry_tielu",
    name: "铁路运输",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 76,
        parentId: 75,
        type: "industry_tielu",
        name: "中欧班列",
        label1: "",
        label2: "",
        label3: "",
        label4: "",
        label5: "",
        label6: "",
        labelArr:
          "铁路大通道,珠三角场站 — 霞凝站 — 中亚/中欧,水铁联运大通道,珠三角码头 — 路口铺 — 武汉地区,国际过境大通道,长沙北 —— 中亚/中欧",
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356207112.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 77,
        parentId: 75,
        type: "industry_tielu",
        name: "湘粤非联运",
        label1: "",
        label2: "",
        label3: "",
        label4: "",
        label5: null,
        label6: null,
        labelArr:
          "以株洲、郴州为主节点和始发站,实现与广州至非洲大陆的海运航线无缝对接,联通欧美、拉美、中东、东南亚等地,拓展进出口海运业务",
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356248597.png,https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356248598.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 78,
        parentId: 75,
        type: "industry_tielu",
        name: "铁运货代",
        label1: "",
        label2: "",
        label3: "",
        label4: "",
        label5: null,
        label6: null,
        labelArr: "灵活分配,按时可靠送达,绿色减排,降本增效",
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356335252.png,https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356335268.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 79,
        parentId: 75,
        type: "industry_tielu",
        name: "铁运资源",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [
          {
            id: 80,
            parentId: 79,
            type: "industry_tielu",
            name: "醴陵南货场",
            label1: "",
            label2: "",
            label3: "",
            label4: "",
            label5: "",
            label6: "",
            labelArr:
              "面积为30000㎡铁路专用货场，可容纳1500个TEU,铁路装卸作业线两条，可同时作业24个车,全天候作业5-6批次,全年货场货物吞吐量：40000TEU,达到110万吨,配备集装箱专业装卸机械设备正面吊、叉车、短驳自卸车辆以及“掏柜”专业服务设备,配备专业装卸团队，全天24小时作业",
            img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356437486.png",
            content: null,
            link: null,
            children: [],
          },
          {
            id: 81,
            parentId: 79,
            type: "industry_tielu",
            name: "易家湾货场",
            label1: "",
            label2: "",
            label3: "",
            label4: "",
            label5: null,
            label6: null,
            labelArr:
              "面积20000㎡铁路散装货场,铁路专用520m装卸股道线，可停放18个车皮24小时循环作业,全年货场货物吞吐量：100万吨,地理位置优势：货场可以辐射周边50公里地区",
            img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356535088.png",
            content: null,
            link: null,
            children: [],
          },
          {
            id: 82,
            parentId: 79,
            type: "industry_tielu",
            name: "路口铺货场",
            label1: "",
            label2: "",
            label3: "",
            label4: "",
            label5: null,
            label6: null,
            labelArr:
              "面积为66000㎡铁路专用货场,储运仓库线2条，可同时作业35个左右，连接入口6个车位，同时可作业41个车,洞庭湖炭肥厂专用线：有2条作业线，32个车位,云溪石化专用线：可设计出停放12个车左右长度的股道，设计运输能力是300万吨，空箱堆放场地预计有8000㎡左右",
            img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356583411.png,https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356609106.png",
            content: null,
            link: null,
            children: [],
          },
        ],
      },
    ],
  },
  industry_shuilu: {
    id: 83,
    parentId: 0,
    type: "industry_shuilu",
    name: "水路运输",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 84,
        parentId: 83,
        type: "industry_shuilu",
        name: "海铁联运",
        label1: "",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: "长株潭««往返»»珠三角  18小时",
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356725959.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 85,
        parentId: 83,
        type: "industry_shuilu",
        name: "水铁联运",
        label1: "",
        label2: "",
        label3: "",
        label4: null,
        label5: null,
        label6: null,
        labelArr: "重庆、武汉、安徽,岳阳港、路口铺货场,湖广各地",
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356783524.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 86,
        parentId: 83,
        type: "industry_shuilu",
        name: "港口资源",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [
          {
            id: 87,
            parentId: 86,
            type: "industry_shuilu",
            name: "城陵矶港",
            label1: "",
            label2: "",
            label3: "",
            label4: null,
            label5: null,
            label6: null,
            labelArr:
              "湖南省岳阳市,长江八大深水良港之一,湖南省唯一的国家一类口岸",
            img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356855859.png",
            content: null,
            link: null,
            children: [],
          },
          {
            id: 88,
            parentId: 86,
            type: "industry_shuilu",
            name: "霞凝港",
            label1: "",
            label2: "",
            label3: "",
            label4: null,
            label5: null,
            label6: null,
            labelArr:
              "湖南省长沙市,湘江流域最深的内河港口之一,我国中部地区最大的物流园区",
            img: null,
            content: null,
            link: null,
            children: [],
          },
          {
            id: 89,
            parentId: 86,
            type: "industry_shuilu",
            name: "石龙港",
            label1: "",
            label2: "",
            label3: null,
            label4: null,
            label5: null,
            label6: null,
            labelArr: "广东省东莞市,东江交通运输枢纽",
            img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693356918966.png",
            content: null,
            link: null,
            children: [],
          },
        ],
      },
    ],
  },
  industry_cangchu: {
    id: 90,
    parentId: 0,
    type: "industry_cangchu",
    name: "仓储统配",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: null,
    content: null,
    link: null,
    children: [
      {
        id: 91,
        parentId: 90,
        type: "industry_cangchu",
        name: "仓储服务",
        label1: "",
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: "长株潭««往返»»珠三角  18小时",
        img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693357003383.png",
        content: null,
        link: null,
        children: [],
      },
      {
        id: 92,
        parentId: 90,
        type: "industry_cangchu",
        name: "统配服务",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [],
      },
      {
        id: 93,
        parentId: 90,
        type: "industry_cangchu",
        name: "仓储资源",
        label1: null,
        label2: null,
        label3: null,
        label4: null,
        label5: null,
        label6: null,
        labelArr: null,
        img: null,
        content: null,
        link: null,
        children: [
          {
            id: 94,
            parentId: 93,
            type: "industry_cangchu",
            name: "立三货场",
            label1: "",
            label2: null,
            label3: null,
            label4: null,
            label5: null,
            label6: null,
            labelArr: "占地面积2万多平米",
            img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693357106790.png",
            content: null,
            link: null,
            children: [],
          },
          {
            id: 95,
            parentId: 93,
            type: "industry_cangchu",
            name: "汽车城",
            label1: "",
            label2: null,
            label3: null,
            label4: null,
            label5: null,
            label6: null,
            labelArr: "占地面积1万多平米",
            img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693357136697.png",
            content: null,
            link: null,
            children: [],
          },
          {
            id: 96,
            parentId: 93,
            type: "industry_cangchu",
            name: "易家湾",
            label1: "",
            label2: null,
            label3: null,
            label4: null,
            label5: null,
            label6: null,
            labelArr: "占地面积3万多平米",
            img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/1693357188676.png",
            content: null,
            link: null,
            children: [],
          },
        ],
      },
    ],
  },
  home_video: {
    id: 97,
    parentId: 0,
    type: "home_video",
    name: "首页视频",
    label1: null,
    label2: null,
    label3: null,
    label4: null,
    label5: null,
    label6: null,
    labelArr: null,
    img: "https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/axvideo.mp4",
    content: null,
    link: null,
    children: []
  }
};
