<template>
  <div class="nav">
    <div class="logo">
      <router-link to="/">
        <img style="height: 50px;cursor: pointer;" src="../assets/logo.png" />
      </router-link>
    </div>
    <div class="menu">
      <ul class="nav_ul">
        <li><router-link to="/home">首页</router-link></li>
        <li class="ddd"><router-link to="/industry">主营业务</router-link>
          <div class="dropdown_content">
            <div class="divsss">
              <p><router-link to="/industry/gonglu">公路运输</router-link></p>
              <p><router-link to="/industry/tielu">铁路运输</router-link></p>
              <p><router-link to="/industry/shuilu">水路运输</router-link></p>
              <p><router-link to="/industry/cangchu">仓储统配</router-link></p>
            </div>
          </div>
        </li>
        <li><router-link to="/newsCenter">新闻中心</router-link></li>
        <li><router-link to="/home#cooperation">合作伙伴</router-link></li>
        <li><router-link to="/about">关于我们</router-link></li>
        <li><router-link to="/join">加入我们</router-link></li>
<!--        <li><router-link to="/contact">联系我们</router-link></li>-->
      </ul>
    </div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  name: 'HeadNav',
  props: {
    msg: String
  },
  data() {
    return {};
  },
  components: { RouterLink }
}
</script>

<style scoped>
.nav {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  text-align: center;
  z-index: 2;
}

.logo {
  width: 30%;
  flex-direction: column;
}

.menu {
  width: 50%;
  height: 70px;
}

.nav_ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 22px;
}

.nav_ul li {
  list-style-type: none;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  font-size: 22px;
}

.ddd:hover .dropdown_content {
  display: block;
  z-index: 999;
}

.divsss p {
  text-align: center;
  line-height: 28px;
  color: #FFFFFF;
}

.dropdown_content {
  display: none;
  position: absolute;
  width: 100px;
  background: rgba(155, 157, 158, 0.28);
}

a {
  text-decoration: none;
  color: white;
}

.router-link-active {
  text-decoration: none;
  color: #cbc8c8;
}
</style>
