<template>
  <div id="home">
<!--    <el-carousel :height="computeHeight">-->
<!--      <el-carousel-item v-for="(item, index) in imgUrlArr($store.state.config['home_banner'].img)" :key="index">-->
<!--        <img :src="item" style="width: 100%;" />-->
<!--      </el-carousel-item>-->
<!--    </el-carousel>-->

    <video-player class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions"></video-player>

    <img class="slogan" src="@/assets/slogan.png">

    <div class="scroll-more">
      <b>
        <i class="el-icon-arrow-down" style="font-size: 30px;"></i>
      </b>
      <p >滑动探索更多</p>
    </div>
    <div class="block1">
      <div class="b-titlebar bsb">
        <div class="bt-left">最新资讯</div>
      </div>
      <ul class="nav">
        <li><router-link to="/newsCenter?type=0">公司新闻</router-link></li>
        <li><router-link to="/newsCenter?type=1">行业资讯</router-link></li>
      </ul>
      <div class="cc">
        <div class="c1" v-if="newsList[0]" @click="navTo(newsList[0])">
          <img :src="newsList[0].coverUrl" style="object-fit:contain" />
          <div class="news">
            <p class="tit">{{ newsList[0].title }}</p>
            <p class="tim">{{ newsList[0].publishTime }}</p>
            <p class="des">{{ newsList[0].excerpt }}
            </p>
          </div>
        </div>
        <div class="c2">
          <div class="news" v-if="newsList[1]" @click="navTo(newsList[1])">
            <p class="tit">{{ newsList[1].title }}</p>
            <p class="tim">{{ newsList[1].publishTime }}</p>
            <p class="des">{{ newsList[1].excerpt }}</p>
          </div>
          <div class="line"></div>
          <div class="news" v-if="newsList[2]" @click="navTo(newsList[2])">
            <p class="tit">{{ newsList[2].title }}</p>
            <p class="tim">{{ newsList[2].publishTime }}</p>
            <p class="des">{{ newsList[2].excerpt }}</p>
          </div>
          <div class="line"></div>
          <div class="news" v-if="newsList[3]" @click="navTo(newsList[3])">
            <p class="tit">{{ newsList[3].title }}</p>
            <p class="tim">{{ newsList[3].publishTime }}</p>
            <p class="des">{{ newsList[3].excerpt }}</p>
          </div>
          <div class="line"></div>
          <div class="news" v-if="newsList[4]" @click="navTo(newsList[4])">
            <p class="tit">{{ newsList[4].title }}</p>
            <p class="tim">{{ newsList[4].publishTime }}</p>
            <p class="des">{{ newsList[4].excerpt }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="block2">
      <div class="b-titlebar bsb">
        <div class="bt-left">{{ $store.state.config['home_industry'].name }}</div>
        <div class="bt-right"><router-link to="/industry" style="color:#504f4f;">更多 ></router-link></div>
      </div>
      <div class="dd">
        <div class="d1" v-for="(item, index) in $store.state.config['home_industry'].children" :key="index">
          <img :src="item.img" class="d1_img1" />
          <img src="../assets/img/meng.png" class="d1_img2" />
          <p class="d1_p1">{{ item.name }}</p>
          <p class="d1_p2">{{ item.label1 }}</p>
        </div>
      </div>
    </div>
    <div class="block3">
      <div class="b-titlebar bsb">
        <div class="bt-left" style="color: white;">{{ $store.state.config['home_about'].name }}</div>
      </div>
      <div class="ee">
        <div class="e1" v-html="$store.state.config['home_about'].content">
        </div>
        <div class="e2">
          <img :src="$store.state.config['home_about'].img">
        </div>
      </div>
      <div class="more"><router-link to="/about" style="color:white;">了解更多 ></router-link></div>
    </div>
    <div class="block4">
      <div class="b-titlebar bc">
        <div class="bt-center" id="cooperation">合作伙伴</div>
      </div>
      <div class="nav">
        <el-button-group>
          <el-button icon="el-icon-s-help" style="color: #182876;" @click="showLogo = 'ziyuan'">合作资源</el-button>
          <el-button icon="el-icon-s-opportunity" style="color: #182876;" @click="showLogo = 'kehu'">合作客户</el-button>
        </el-button-group>
      </div>
      <div class="logo" v-if="showLogo == 'ziyuan'">
        <div style="margin: 10px 20px; text-align: center"
          v-for="(item, index) in $store.state.config['home_cooperation_ziyuan'].children" :key="index">
          <img class="logo-img" :src="item.img" :alt="item.name" />
        </div>
      </div>
      <div class="logo" v-if="showLogo == 'kehu'">
        <div style="margin: 10px 20px; text-align: center"
          v-for="(item, index) in $store.state.config['home_cooperation_kehu'].children" :key="index">
          <img class="logo-img" :src="item.img" :alt="item.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import { listArticle } from '@/api/article'
export default {
  name: 'Home',
  components: {
    videoPlayer
  },
  data() {
    return {
      showLogo: 'ziyuan',
      newsList: [],
      playerOptions: {
        autoplay: true,  // 如果为true,浏览器准备好时开始回放
        muted: true,     // 默认情况下将会消除任何音频。
        loop: true,      // 是否视频一结束就重新开始。
        preload: 'auto',  // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9',  // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true,  // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4",  // 类型
          src: 'https://axwl-mhwz.oss-cn-shenzhen.aliyuncs.com/filename/axvideo.mp4'             // url地址
        }],
        notSupportedMessage: '此视频暂无法播放，请稍后再试',  // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: false,
        // controlBar: {
        //   timeDivider: false,           // 当前时间和持续时间的分隔符
        //   durationDisplay: false,       // 显示持续时间
        //   remainingTimeDisplay: false, // 是否显示剩余时间功能
        //   fullscreenToggle: false,       // 是否显示全屏按钮
        //   progressControl:false,    // 进度条
        //   playToggle:false,     // 暂停和播放键
        //   volumeControl: false, // 声音控制键
        // }
      }
    }
  },
  computed: {
    //计算高度
    computeHeight() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        return '460px';
      } else {
        return '250px';
      }
    }
  },
  watch: {
    '$route': 'getPath'
  },
  mounted() {
    let hash = window.location.hash;
    if (hash) {
      // document.querySelector(hash).scrollIntoView(true);
      document.querySelector(hash).scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
    }
  },
  created() {
    this.getNewsList()
  },
  methods: {
    getPath() {
      // 获取hash地址信息
      var hash = window.location.hash;
      if (hash) {
        document.querySelector(hash).scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
      }
    },
    getNewsList() {
      listArticle({
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        this.newsList = res.rows;
      })
    },
    navTo(item) {
      if (item.originalType === '0'){
        let routeData = this.$router.resolve({
          path: `/newsCenter/news`,
          query: { id: item.id },  //参数
        });
        window.open(routeData.href, "_blank");
      }else if(item.originalType === '1'){
        window.open(item.exUrl, '_blank');
      }
    },
  }
}
</script>

<style scoped>
.block1 {
  padding: 20px 10%;
  align-items: center;
}

.b-titlebar {
  display: flex;
  flex-direction: row;
}

.bsb {
  justify-content: space-between;
}

.bc {
  justify-content: center;
}

.bt-left {
  font-size: 35px;
  font-weight: 700;
  color: #444444;
}

.bt-center {
  font-size: 35px;
  font-weight: 700;
  color: #444444;
}

.bt-right {
  font-size: 20px;
  font-weight: 500;
  color: #504f4f;
}

.block1 .nav {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.block1 .nav li {
  margin-right: 50px;
  font-size: 20px;
}

.cc {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.cc .c1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  background-color: #F8F8F8;
}

.cc .c2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  background-color: #F8F8F8;
}

.c2 .line {
  width: 100%;
  height: 6px;
  background-color: white;
}

.news {
  margin-left: 43px;
}

.news .tit {
  color: #23337F;
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: 1px;
}

.news .tim {
  color: #8F8F8F;
  font-size: 14px;
  line-height: 20px;
}

.news .des {
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}

.block2 {
  padding: 20px 10%;
  align-items: center;
}

.titlebar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dd {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
}

.d1 {
  position: relative;
  margin: 30px;
}

.d1_img1 {
  width: 30em;
  height: 25em;
  height: auto;
  object-fit: fill;
}

.d1_img2 {
  width: 30em;
  height: 10em;
  position: absolute;
  left: 0;
  top: 7em;
}

.d1_p1 {
  width: 96px;
  height: 33px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: white;
  line-height: 33px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 168px;
  left: 32px;
}

.d1_p2 {
  width: 337px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: white;
  line-height: 20px;
  position: absolute;
  top: 211px;
  left: 32px;
}

.block3 {
  padding: 20px 10%;
  align-items: center;
  background-image: url("../assets/img/bg1.png");
}

.ee {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.ee .e1 {
  max-width: 60%;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 40px;
}

.ee .e2 {
  max-width: 30%;
  margin-left: 10%;
}

.ee .e2 img {
  width: 100%;
}

.block3 .more {
  width: 140px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.block4 {
  padding: 20px 10%;
  align-items: center;
}

.block4 .nav {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.block4 .logo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 20px auto;
  align-items: center;
  height: auto
}

.logo-img {
  height: 90px;
  object-fit: contain
}

a {
  text-decoration: none;
  color: black;
}

.router-link-active {
  text-decoration: none;
  color: gray;
}

.slogan{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.scroll-more{
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  text-align: center;
}

.scroll-more b{
  display: block;
  margin: auto;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.scroll-more p{
  color: white;
}

.scroll-more b i{
  color: white;
  animation: jumpBoxHandler 1.8s infinite;/* 1.8s 事件完成时间周期 infinite无限循环 */
}

@keyframes jumpBoxHandler { /* css事件 */
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 30px); /* 可配置跳动方向 */
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>
